.myModal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    font-size: 0.1px;
    /* visibility: hidden; */
    display: none;
    opacity: 0;
    background: rgba(0, 0, 0, 0.3);
    /* background: rgba(50, 80, 80, 0.8); */
    transition: opacity 300ms ease, visibility 300ms ease;
    will-change: opacity, visibility;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 30px;
    z-index: 1001;
  }
  
  .myModal:after {
    content: "";
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
  }
  .modal__content {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
    background: #fff;
    padding: 30px 50px;
    border-radius: 5px;
    transform: translateY(50px);
    transition: transform 300ms ease;
    will-change: transform;
    width: 80%;
    max-width: 400px;
  }
  .myModal.is-active {
    opacity: 1;
    /* visibility: visible; */
    display: block;
  }
  .myModal.is-active .modal__content {
    transform: translateY(0);
  }

  
  .wack button {
    display: table;
    /* line-height: 30px; */
    padding: 10px 15px;
    border: none;
    margin: 0 auto;
    border-radius: 3px;
    background: #1475CF;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    transition: background 300ms ease;
    font-size: 13px;
  }
  
  .wack button:hover {
    background: #1c7eda;
  }
  
  .myModal h1 {
    margin: 0 0 10px;
    font-size: 24px;
  }
  .myModal p {
    margin: 0 0 25px;
    font-size: 15px;
  }
  
  
  /* my code: charles */
  .buttonContainer{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  #noButton{
    background-color: white;
    color: #121212;
    border: 1px solid #121212;
  }
  
  #yesButton{
    background-color: #132f50;
    display: flex;
    align-items: center;
    gap: 2px;
  }

  #yesButton:hover{
    background-color: rgb(206, 16, 54);
  }