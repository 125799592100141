/* // X-Small devices (portrait phones, less than 576px) */

@media (max-width: 575.98px) { 
    .tab-row {
        display: grid;
        grid-template-columns: repeat(2, 1fr); 
        grid-template-areas: "sticker-no sticker-no" 
                            "first second"
                            "third fourth"
                            "repeater-buttons repeater-buttons";   
    } 

    .sticker-number {
        grid-row: 1;
        grid-area: sticker-no;
        display: flex;
        justify-content: center;
    } 

    .first-cell {
        grid-row: 2; 
        grid-area: first; 
    }

    .second-cell {
        grid-row: 2;
        grid-area: second;
    }

    .third-cell {
        grid-row: 3;
        grid-area: third;
    }

    .fourth-cell {
        grid-row:3;
        grid-area: fourth;
    }

    .fifth-cell {
        grid-row:4;
        grid-area: repeater-buttons;
        
    }

    .form-buttons {
        width: 100%;
        justify-content: center;
        display: flex;
        gap: 5px;
    } 
    
    .form-field {
        width: 160px;
    }  

    .new-client-cta {
        border: none;
        padding: 8px;
        border-radius: 5px;
    }


    .chat-container {
        padding-left:380px;
    }

    .dates {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }

    #policy-end-date {
        width: 20vw;
    }
}

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
    .tab-row {
        display: grid;
        grid-template-columns: repeat(2, 1fr); 
        grid-template-areas: "sticker-no sticker-no" 
                            "first second"
                            "third fourth"
                            "repeater-buttons repeater-buttons";   
    } 

    .sticker-number {
        grid-row: 1;
        grid-area: sticker-no;
        display: flex;
        justify-content: center;
    } 

    .first-cell {
        grid-row: 2; 
        grid-area: first; 
    }

    .second-cell {
        grid-row: 2;
        grid-area: second;
    }

    .third-cell {
        grid-row: 3;
        grid-area: third;
    }

    .fourth-cell {
        grid-row:3;
        grid-area: fourth;
    }

    .fifth-cell {
        grid-row:4;
        grid-area: repeater-buttons;
        
    }

    .form-buttons {
        width: 100%;
        justify-content: center;
        display: flex;
        gap: 5px;
    }  

    .dates {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }

    #policy-end-date {
        width: 20vw;
    }
    
    .form-field {
        width: 160px;
    } 

    .new-client-cta {
        border: none;
        padding: 8px;
        border-radius: 5px;
    }
}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
    .tab-row {
        display: grid;
        grid-template-columns: repeat(2, 1fr); 
        grid-template-areas: "sticker-no sticker-no" 
                            "first second"
                            "third fourth"
                            "repeater-buttons repeater-buttons";   
    } 

    .sticker-number {
        grid-row: 1;
        grid-area: sticker-no;
        display: flex;
        justify-content: center;
    } 

    .first-cell {
        grid-row: 2; 
        grid-area: first; 
    }

    .second-cell {
        grid-row: 2;
        grid-area: second;
    }

    .third-cell {
        grid-row: 3;
        grid-area: third;
    }

    .fourth-cell {
        grid-row:3;
        grid-area: fourth;
    }

    .fifth-cell {
        grid-row:4;
        grid-area: repeater-buttons;
        
    }

    .form-buttons {
        width: 100%;
        justify-content: center;
    } 

    .new-client-cta {
        border: none;
        padding: 8px;
        border-radius: 5px;
        color:#ffffff;
        background-color:#1475cf;
    }

    .chat-container {
        padding-right: 100px;
    }

    .dates {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }

    #policy-end-date {
        width: 20vw;
    }

    .form-buttons {
        width: 100%;
        justify-content: center;
        display: flex;
        gap: 5px;
    } 





}

/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
    .new-client-cta {
        border: none;
        padding: 8px;
        border-radius: 5px;
        color:#ffffff;
        background-color:#1475cf;
    } 
}

/* // X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) { 
    .new-client-cta {
        border: none;
        padding: 8px;
        border-radius: 5px;
        color:#ffffff;
        background-color:#1475cf;
    }

}


@media (min-width: 1400px) {
    .new-client-cta {
        border: none;
        padding:8px;
        border-radius: 5px;
        color:#ffffff;
        background-color: #1475cf; 

    }

    .form-buttons {
        width: 100%;
        justify-content: center;
        display: flex;
        flex-direction: column;
        gap: 5px;
        /* align-items: center; */
        padding-left:20px;
    }

    .dates {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }

    #policy-end-date {
        width: 10.4vw;
    }

    .chat-container {
        padding-right: 100px;
    }
}

@media (max-width: 390px) {

    .tab-row {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .form-field {
        width: 260px;
    }

   #chatbox {
       margin-right:34px;
   }


}