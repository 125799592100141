

.required{
    color: crimson;
}

form{
    padding: 30px 30px;
}

#row{
    display: flex;
}

.client_name{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 20px;
    width: 550px;
}


.client_name input{
    border-radius: 3px;
    border: 1px solid #000;
    padding: 5px 5px;
}

#dob{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 20px;
}

#dob #date{
    display: flex;
    flex-direction: column;
    justify-self: space-between;
}

#contact{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 20px;
}

#contact div{
    display: flex;
    flex-direction: column;
}

#address{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    /* padding: 20px; */
    justify-content: space-between;
}

#address textarea{
    resize: none;
    height: 50px;
}

#upload{
    border: 1px solid brown;
    margin-bottom: 20px;
    padding: 20px;
}

#submit{
    display: flex;
    justify-content: flex-end;
}

#gender{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

#addForm{
    display: flex;
    justify-content: "center";
    align-items: center;
    background-color: #fff;
    margin-top: 60px;
    margin-bottom: 30px;
    border-radius: 10px;
}

#addForm form{
    /*margin: auto;*/
}

.submitcta{
    margin: 0;
}

.addFormGroups {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.addFormRadio{
    margin-right: 5px;
}

.organisation-columns{
    display: grid;
    grid-template-columns: auto auto;
    gap: 50px;
}

.gender-options{
    display: flex;
    gap: 10px;
}



@media (max-width: 798px) {
    .organisation-columns{
        display: flex;
        flex-direction: column;
    }


}

@media (max-width:320px) {
    .chat-container {
        margin-left:25px;
        
    }
}

@media (max-width: 480px) {
    .boom {
        width: 100vw;
    }

    .row-fields {
        display: flex;
        flex-direction:column;
    }
    
}

@media (max-width: 768px) {
    .chat-container{
        padding-right:90px;
    }

    .boom {
        width: 100vw;
    }
}

/* @media (max-width: 1024px) {
    
}

@media (max-width: 1200px) {
    
}

@media (min-width: 1201px) {
    
} */